import React from 'react';
import styled from 'styled-components';

const Page = styled.div`
  margin-top: 7.5vw;
  min-height: 50vh;
  header {
    h1 {
      font-size: 3em;
      padding-bottom: 30px;
    }
  }
  .siteWrapper {
    grid-column: 1 / span 16;
  }
`;

const ArtistStyles = styled.div``;
export default function Artist() {
  return (
    <>
      <Page>
        <div className="SiteContainer">
          <div className="siteWrapper">
            {' '}
            <ArtistStyles>THIS</ArtistStyles>
          </div>
        </div>
      </Page>
    </>
  );
}
